import styled from "styled-components";

export const DesktopStyled = styled.div`
    padding-top: 55px;

    background: var(--colors-dark-blue);
    border-top: 1px solid var(--colors-light-blue-grey);
`;

export const Top = styled.div`
    padding-bottom: 60px;

    color: var(--colors-white);
`;

export const Container = styled.div`
    display: flex;

    width: 100%;
    max-width: 1180px;

    margin: 0 auto;
    padding: 0 20px;
`;

export const Brand = styled.div`
    margin-right: 155px;
`;

export const Logo = styled.div`
    width: 144px;
    margin-bottom: 19px;

    cursor: pointer;
`;

export const Copyright = styled.p`
    color: var(--colors-blue);
    font-size: 14px;
    line-height: 30px;
`;

export const Contact = styled.div``;

export const Heading = styled.h2`
    margin-bottom: 6px;

    font-size: 20px;
    line-height: 30px;
`;

export const Text = styled.div`
    margin-bottom: 5px;

    p {
        font-size: 14px;
        line-height: 30px;
    }
`;

export const Buttons = styled.div`
    max-width: 410px;

    button,
    a:not(:last-child) {
        margin-right: 28px;
        margin-bottom: 10px;
    }
`;

export const Bottom = styled.div`
    display: flex;
    align-items: center;

    height: 100px;

    background: var(--colors-white);
`;

export const Payment = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h2`
    margin-right: 14px;

    font-size: 14px;
    line-height: 30px;
`;

export const Logos = styled.div`
    img {
        width: 60px;
    }
`;

export const Delivery = styled.div`
    display: flex;
    align-items: center;

    margin-left: auto;

    font-size: 14px;
    line-height: 30px;

    img {
        width: 60px;
    }
`;
