import styled from "styled-components";

import media from "../../../constants/media";

export const FooterStyled = styled.div`
    margin-top: 35px;

    ${media.mdUp`
        padding-top: 94px;
        margin-top: 0;
        background-color: var(--colors-dark-blue);
    `}
`;
