import PropTypes from "prop-types";

export default function IconArrowRight({ width, height, fill, ...props }) {
    return (
        <svg
            viewBox="0 0 24 24"
            width={width}
            height={height}
            fill={fill}
            {...props}
        >
            <g
                stroke={fill}
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
            >
                <path strokeLinejoin="round" d="M18 6.75l5 5.185-4.643 4.815" />
                <path d="M23 12H0" />
            </g>
        </svg>
    );
}

IconArrowRight.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string
};

IconArrowRight.defaultProps = {
    width: 24,
    height: 24,
    fill: "var(--colors-black)"
};
