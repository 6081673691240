import PropTypes from "prop-types";

function IconMenu({ stroke }) {
    return (
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g
                stroke={stroke}
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
            >
                <path d="M16.833 20H1.5M22.583 12H1.5M22.583 4H1.5" />
            </g>
        </svg>
    );
}

IconMenu.propTypes = {
    stroke: PropTypes.string
};

IconMenu.defaultProps = {
    stroke: "#FFF"
};

export default IconMenu;
