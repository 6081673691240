import PropTypes from "prop-types";
import Modal from "react-modal";

import Head from "components/shared/head/Head";
import Header from "components/shared/header/Header";
import Footer from "components/shared/footer/Footer";

import { LayoutStyled } from "./Layout.styled";

Modal.setAppElement("#__next");

export default function Layout({ children, title, description, metadata }) {
    return (
        <LayoutStyled>
            <Head metadata={metadata} title={title} description={description} />
            <Header />
            {children}
            <Footer />
        </LayoutStyled>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    metadata: PropTypes.shape({
        canonical: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string
    }),
    title: PropTypes.string
};

Layout.defaultProps = {
    metadata: undefined,
    title: undefined,
    description: undefined
};
