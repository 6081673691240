import { useMediaQueryContext } from "lib/mediaQueryContext";

import { FooterStyled } from "./Footer.styled";
import Mobile from "./mobile/Mobile";
import Desktop from "./desktop/Desktop";

function Footer() {
    const { mobileView, tabletView, desktopView } = useMediaQueryContext();

    return (
        <FooterStyled>
            {mobileView && <Mobile />}
            {(tabletView || desktopView) && <Desktop />}
        </FooterStyled>
    );
}

export default Footer;
