import Newsletter from "components/footer/newsletter/Newsletter";
import Image from "next/image";
import Link from "next/link";
import MainLogo from "public/images/logo.svg";
import IdealLogo from "public/images/payment-methods/logo-ideal.svg";
import MasterCardLogo from "public/images/payment-methods/logo-mastercard.svg";
import VisaLogo from "public/images/payment-methods/logo-visa.svg";
import PayconiqLogo from "public/images/payment-methods/logo-payconiq.svg";
import PostNlLogo from "public/images/logo-postnl.svg";
import {
    DesktopStyled,
    Top,
    Container,
    Brand,
    Logo,
    Copyright,
    Contact,
    Heading,
    Text,
    Buttons,
    Bottom,
    Payment,
    Title,
    Logos,
    Delivery
} from "./Desktop.styled";
import Button from "../../button/Button";

export default function Desktop() {
    return (
        <DesktopStyled>
            <Top>
                <Container>
                    <Brand>
                        <Link href="/" passHref prefetch={false}>
                            <a>
                                <Logo>
                                    <Image
                                        src={MainLogo}
                                        alt="Nationale Keuze Cadeaukaart"
                                    />
                                </Logo>
                            </a>
                        </Link>
                        <Copyright>© keuzekaart.nl 2020</Copyright>
                    </Brand>
                    <Contact>
                        <Heading>Zakelijk contact opnemen</Heading>
                        <Text>
                            <p>Zakelijke vragen? Bel: +31 85 208 70 18</p>
                            <p>
                                maandag t/m vrijdag: 09:30-12:30 & 13.00-17.00
                            </p>
                        </Text>
                        <Buttons>
                            <Button
                                variant="underline"
                                element="a"
                                href="/helpdesk"
                            >
                                Veelgestelde vragen
                            </Button>
                            <Button
                                variant="underline"
                                element="a"
                                href="/saldocheck"
                            >
                                Saldo checken
                            </Button>
                            <Button
                                variant="underline"
                                element="a"
                                href="/over-ons"
                            >
                                Over ons
                            </Button>
                            <Button
                                variant="underline"
                                element="a"
                                href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                                target="_blank"
                                rel="noopener norefer"
                            >
                                Inwisselen
                            </Button>
                            <Button
                                variant="underline"
                                element="a"
                                href="/algemene-voorwaarden"
                            >
                                Voorwaarden
                            </Button>
                            <Button
                                variant="underline"
                                element="a"
                                href="/cookie-privacy"
                            >
                                Privacyverklaring
                            </Button>
                        </Buttons>
                    </Contact>
                    <div>
                        <Newsletter />
                    </div>
                </Container>
            </Top>
            <Bottom>
                <Container>
                    <Payment>
                        <Title>Betaalmogelijkheden</Title>
                        <Logos>
                            <Image src={IdealLogo} alt="iDeal" />
                            <Image src={MasterCardLogo} alt="Mastercard" />
                            <Image src={VisaLogo} alt="Visa" />
                            <Image src={PayconiqLogo} alt="Payconiq" />
                        </Logos>
                    </Payment>
                    <Delivery>
                        <Title>Verzending</Title>
                        <Image src={PostNlLogo} alt="PostNL" />
                        Voor 17.00 uur besteld = vandaag verstuurd
                    </Delivery>
                </Container>
            </Bottom>
        </DesktopStyled>
    );
}
