import styled from "styled-components";
import media from "constants/media";

import { ButtonStyled } from "components/shared/button/Button.styled";
import { InputStyled } from "components/shared/input/Input.styled";
import SharedText from "components/shared/text/Text";

export const NewsletterStyled = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
`;

export const Text = styled(SharedText)`
    & > strong {
        font-weight: bold;
    }
`;

export const Form = styled.form`
    width: 100%;
`;

export const Email = styled.div`
    position: relative;

    display: flex;
    align-items: flex-start;

    ${ButtonStyled} {
        position: absolute;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        padding: 0;

        span,
        svg {
            height: 100%;
        }

        ${media.mdUp`
            width: 50px;
            height: 50px;
        `}
    }

    ${InputStyled} {
        input {
            height: 40px;
            padding-right: 45px;

            background-color: transparent;
            border: 1px solid var(--colors-light-blue-grey);
            border-radius: var(--border-radius-extra-large);

            ${media.mdUp`
                border-color: var(--colors-light-dark-blue);
                color: var(--colors-light-grey);
                height: 50px;
                padding-right: 50px;

                &::placeholder {
                    color: var(--colors-light-grey);
                }
            `}
        }
    }
`;

export const SuccessMessage = styled.div`
    flex-basis: 100%;
    width: 100%;
    margin-top: -20px;

    color: var(--colors-green);

    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
`;
