import { useState } from "react";
import Newsletter from "components/footer/newsletter/Newsletter";
import Image from "next/image";
import FooterCurveSvg from "public/images/footer-curve.svg";
import LogoDark from "public/images/logo-dark.svg";
import IdealLogo from "public/images/payment-methods/logo-ideal.svg";
import MasterCardLogo from "public/images/payment-methods/logo-mastercard.svg";
import VisaLogo from "public/images/payment-methods/logo-visa.svg";
import PayconiqLogo from "public/images/payment-methods/logo-payconiq.svg";
import PostNlLogo from "public/images/logo-postnl.svg";
import CheveronDownImage from "public/images/chevron-down-orange.svg";
import {
    Header,
    Control,
    Curve,
    Chevron,
    Body,
    Container,
    Logo,
    Copyright,
    Text,
    Buttons,
    Row,
    Separator,
    Payment,
    Title,
    Logos,
    Delivery
} from "./Mobile.styled";
import Button from "../../button/Button";

function Mobile() {
    const [expanded, setExpanded] = useState(false);

    return (
        <Header isExpanded={expanded}>
            <Control>
                <Curve>
                    <Image src={FooterCurveSvg} />
                </Curve>
                <Chevron
                    onClick={() => setExpanded(!expanded)}
                    isExpanded={expanded}
                >
                    <Image
                        src={CheveronDownImage}
                        height={35}
                        width={35}
                        alt={
                            expanded ? "Footer inklappen" : "Footer uitklappen"
                        }
                    />
                </Chevron>
            </Control>
            {expanded && (
                <Body>
                    <Container>
                        <Logo
                            src={LogoDark}
                            alt="Nationale Keuze Cadeaukaart"
                        />
                        <Copyright>© keuzekaart.nl 2020</Copyright>
                        <Text>
                            <strong>
                                Zakelijke vragen? Bel: +31 85 208 70 18
                            </strong>
                            maandag t/m vrijdag: 09:30-12:30 & 13.00-17.00
                        </Text>
                        <Buttons>
                            <Row>
                                <Button
                                    variant="underline"
                                    element="a"
                                    href="/helpdesk"
                                >
                                    Veelgestelde vragen
                                </Button>
                                <Button
                                    variant="underline"
                                    element="a"
                                    href="/over-ons"
                                >
                                    Over ons
                                </Button>
                            </Row>
                            <Row>
                                <Button
                                    variant="underline"
                                    element="a"
                                    href="/saldocheck"
                                >
                                    Saldo checken
                                </Button>
                                <Button
                                    variant="underline"
                                    element="a"
                                    href="https://www.cadeaubon.nl/cadeaukaart-keuze/step-one"
                                    target="_blank"
                                    rel="noopener norefer"
                                >
                                    Inwisselen
                                </Button>
                            </Row>
                            <Row>
                                <Button
                                    variant="underline"
                                    element="a"
                                    href="/algemene-voorwaarden"
                                >
                                    Voorwaarden
                                </Button>
                                <Button
                                    variant="underline"
                                    element="a"
                                    href="/cookie-privacy"
                                >
                                    Privacyverklaring
                                </Button>
                            </Row>
                        </Buttons>
                        <Newsletter />
                    </Container>
                    <Separator />
                    <Container>
                        <Payment>
                            <Title>Betaalmogelijkheden</Title>
                            <Logos>
                                <Image src={IdealLogo} alt="iDeal" />
                                <Image src={MasterCardLogo} alt="Mastercard" />
                                <Image src={VisaLogo} alt="Visa" />
                                <Image src={PayconiqLogo} alt="Payconiq" />
                            </Logos>
                            <Delivery>
                                <Image src={PostNlLogo} alt="PostNL" />
                                Voor 17.00 uur besteld
                                <br /> = vandaag verstuurd
                            </Delivery>
                        </Payment>
                    </Container>
                </Body>
            )}
        </Header>
    );
}

export default Mobile;
