import styled from "styled-components";
import media from "constants/media";
import Image from "next/image";

export const Header = styled.div`
    position: relative;

    transform-origin: 0 0;

    &::before {
        position: absolute;
        top: -35px;

        display: block;
        width: 100%;
        height: 34px;

        background-color: transparent;

        content: "";

        ${media.mdUp`
            height: 94px;
        `}
    }
`;

export const Control = styled.div`
    height: 10px;

    background: var(--colors-white);
`;

export const Curve = styled.div`
    position: relative;
    top: 10px;
    left: 50%;
    z-index: 2;

    display: flex;
    justify-content: center;

    width: 100%;

    transform: translate(-50%, -100%);
`;

export const Chevron = styled.button`
    position: absolute;
    top: 18px;
    left: 50%;
    z-index: 3;

    background: none;
    border: 0;

    transform: ${({ isExpanded }) =>
        isExpanded
            ? "translate(-50%, -100%) rotate(180deg)"
            : "translate(-50%, -100%) rotate(0deg)"};

    cursor: pointer;

    transition: 0.3s transform ease-in-out;
`;

export const Body = styled.div`
    padding: 30px 0;

    color: var(--colors-dark-blue);
    text-align: center;

    background: var(--colors-white);
`;

export const Container = styled.div`
    max-width: 320px;

    margin: 0 auto;
    padding: 0 20px;
`;

export const Logo = styled(Image)`
    margin-bottom: 6px;

    cursor: pointer;
`;

export const Copyright = styled.h2`
    margin-bottom: 25px;

    color: var(--colors-light-blue-grey);
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
`;

export const Text = styled.p`
    margin-bottom: 30px;

    font-size: 14px;
    line-height: 25px;

    strong {
        display: block;
    }
`;

export const Buttons = styled.div`
    margin-bottom: 40px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 19px;
`;

export const Separator = styled.div`
    margin-bottom: 20px;

    border-bottom: 1px solid var(--colors-light-grey);
`;

export const Payment = styled.div``;

export const Title = styled.h2`
    margin-bottom: 10px;

    font-size: 14px;
    line-height: 30px;
`;

export const Logos = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 14px;

    img {
        width: 60px;
    }
`;

export const Delivery = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 260px;
    margin: 0 auto;

    font-size: 14px;
    line-height: 25px;
    text-align: left;

    img {
        width: 80px;
    }
`;
